@import "assets/styles/variables";
@import "~bootstrap/scss/functions.scss";

// Animation for children of the ExpandableCard component
.animatedContainer {
  transition: max-height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
  max-height: 0;
  opacity: 0;

  &.containerActive {
    max-height: 1000px;
    opacity: 1;
  }

  &.containerDisabled {
    max-height: 1000px;
    opacity: 1;
  }
}

// Animation for children of the ExpandableCard component

.titleBox {
  border-radius: 5px;
  padding: 20px;
  margin: 0 12px;
  font-weight: 700;

  @media screen and (width > 600px) {
    margin: 0;
  }
}

.active {
  background-color: $primary;
  color: white;
}

.disabled {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $grey-8;
  color: $new-black;
  margin: 0px 12px 20px;

  @media screen and (width > 600px) {
    margin: 0 0 20px;
  }
}

.subtitle {
  font-weight: 300;
  font-size: 14px;
}

.button {
  border: none;
  color: $medium-blue;
  background-color: transparent;
  font-size: 14px;
  cursor: pointer;
}
