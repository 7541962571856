@import "src/variables.scss";

$red: #d71920;
$black: #000;
$white: #fff;
$gray-100: #f9f9f9;
$gray-200: #e5e5e5;
$gray-400: #cecece;
$gray-500: #b8b8b8;
$gray-600: #808080;

$primary: $red;

$dark: $black;

$font-weight-bolder: 700;

@mixin hero-background {
  background-image: linear-gradient(to bottom, $white 0%, rgba($white, 0.1)),
    url("../images/hero.png");

  @media screen and (min-width: $tablet-min-breakpoint) {
    background-position: center bottom;
  }
}
