@import "assets/styles/variables.scss";
@import "src/variables.scss";

.content {
  padding: 0 5px 39px;
}

.title {
  margin: 15px 5px;
  font-size: 24px;
  font-weight: 700;
  color: $new-black;
}

.subTitle {
  font-size: 14px;
  font-weight: 300;
  color: $grey-1;
  text-transform: uppercase;
}

.card {
  margin-bottom: 20px;
  padding: 16px;
  box-shadow: none;
  border: 1px solid $gray-400;
}

.cardBody {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $dark;
}

.cardHeader {
  display: flex;
  align-items: center;
  font-weight: 700;
}

.cardCopy {
  padding-top: 2px;
  font-size: 14px;
}

.zoneRateCopy {
  display: flex;
  max-width: 250px;

  @media screen and (min-width: $mobile-max-breakpoint) {
    max-width: 100%;
  }
}

.street,
.rateName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.street {
  flex-grow: 1;
  margin-right: 5px;
}

.rateName {
  flex-shrink: 0;
  max-width: 100px;

  @media screen and (min-width: $mobile-max-breakpoint) {
    max-width: 100%;
  }
}

.cardPrice {
  font-size: 14px;
}

.cancelledBadge {
  background: lighten($red, 50);
  font-weight: 300;
}

.arrowIcon {
  filter: invert(80%) sepia(3%) saturate(130%) hue-rotate(0deg) brightness(100%)
    contrast(95%);
  padding-left: 7px;
}
