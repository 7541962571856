@import "assets/styles/variables.scss";

.header {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  line-height: 120%;
  padding: 43px 0 49px;
}

.navbar {
  background-color: $primary;
  padding: 16px 0;
  text-align: center;
  font-size: 0.875rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  white-space: pre-line;
}

.leftButtonContainer {
  flex-shrink: 0;
  min-width: 60px;
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.leftButton {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  padding-left: 12px;
  color: $white;

  i {
    font-size: 10px;
  }
}

.rightButtonContainer {
  flex-shrink: 0;
  min-width: 60px;
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.title {
  text-transform: uppercase;
  font-family: "ProximaNova";
  font-weight: bold;
  font-size: 18px;
  color: $white;
  text-decoration: none;
  height: 100%;
  vertical-align: middle;

  &:hover, &:focus, &:active {
    color: $white;
  }
}

.loginProfileLink {
  line-height: 100%;
  padding-right: 12px;
  vertical-align: middle;
  color: $white;
  font-family: ProximaNova;
  &:hover {
    color: $white;
  }
}

.hamburgerIcon::before {
  line-height: 100%;
  vertical-align: middle;
  font-size: 1.5rem;
  font-weight: bold;
  color: $white;
}
