@import "assets/styles/variables.scss";

.body {
  flex: 1;
  height: 100%;
  max-width: 500px;

  @media screen and (width > 500px) {
    height: fit-content;
    padding: 0;
  }
}

.linkWrapper {
  padding-top: 10px;
  font-size: 0.875rem;
  text-align: center;
}

.cardsContainer {
  margin: 25px 0;

  @media screen and (width <= 500px) {
    flex: 1;
  }
}

.emptyContainer {
  padding: 25px 20px;
  text-align: center;
  border: 1px solid #CECECE;
  border-radius: 5px;

  h5 {
    font-size: 18px;
    font-weight: 700;
  }

  p {
    margin-bottom: 0;
  }
}
