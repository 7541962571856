@import "assets/styles/variables.scss";

.page {
  padding: 18px;
  text-align: center;
}

.header {
  color: $black;
  font-weight: 700;
  font-size: 17px;
  text-decoration: none;
  font-family: ProximaNova;
  text-transform: uppercase;
  &:hover {
    color: $black;
  }
}

.errorImg {
  padding-top: 8rem;
}

.whoops {
  font-family: ProximaNova;
  font-weight: 700;
  font-size: 24px;
}

.errorMessage {
  font-family: ProximaNova;
  font-size: 14px;
  margin: 15px 0;
}
