@import "assets/styles/variables";
@import "~bootstrap/scss/functions.scss";

.wrapper {
  margin: 20px 12px;

  @media screen and (width > 600px) {
    margin: 20px 0;
  }
}

.card {
  box-shadow: none;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  border: none;
  background-color: rgba(0, 113, 235, 0.1);
  color: $medium-blue;
  cursor: pointer;
}

.arrowIcon {
  filter: invert(34%) sepia(82%) saturate(5182%) hue-rotate(201deg)
    brightness(98%) contrast(101%);
}
