@import "assets/styles/variables.scss";

.hero {
  composes: hero from "components/shared/Hero/Hero.module.scss";
}

.heroContent {
  composes: content from "components/shared/Hero/Hero.module.scss";
}

.headline {
  composes: headline from "components/shared/Hero/Headline/Headline.module.scss";
  padding: 15px 0;
}

.brandingBar {
  font-size: 1.063rem;
  text-transform: uppercase;
  font-family: ProximaNova;
  height: 48px;
  align-self: stretch;
  align-items: center;
  color: black;
  padding: 0 19px;
  display: flex;
  text-decoration: none;
}

.brandingBar:hover {
  color: black;
}

.modifierWrapper {
  width: 100%;
  padding: 0 15px;
  margin-bottom: 25px;
}

.modifiers {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 15px;
  color: black;
  font-size: 0.75rem;
}

.changeReservationCopy {
  text-align: center;
  margin-bottom: 25px;
  color: black;
  font-size: 0.75rem;
  line-height: 18px;
}

.modifierButton {
  flex-grow: 1;
  font-size: 0.875rem;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.page {
  min-height: 100%;
  background-color: $gray-100;
}

.plate {
  font-family: ProximaNova;
  font-size: 3.188rem;
  font-weight: bold;
  color: black;
  border: 1px solid black;
  border-radius: 8px;
  text-transform: uppercase;
  padding: 8px 30px;
  text-align: center;
  margin-bottom: 20px;
}

.card {
  margin-bottom: 20px;
}

.detailsCard {
  padding: 20px;
  position: relative;
}

.instructionsCard {
  padding: 22px;
}

.promoCard {
  display: grid;
  grid-template-columns: 50% 50%;
  font-size: 0.875rem;
}

.content {
  padding: 19px 15px 39px;
}

.detailsGrid {
  display: grid;
  grid-template-columns: 28px 1fr;
  grid-template-rows: min-content min-content min-content;
  row-gap: 30px;
}

.detailsHeader {
  text-transform: uppercase;
  line-height: 1;
  padding-bottom: 4px;
  color: $gray-600;
  font-size: 0.875rem;
}

.detailsCopy {
  font-weight: bold;
  font-size: 1rem;
  font-feature-settings: "lnum";
}

.detailsSmallCopy {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: $gray-600;
  text-decoration: none;
}

.instructionsWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 10px;
}

.instructionsHeadline {
  font-weight: bold;
  font-size: 1.25rem;
  padding-left: 25px;
}

.instructionsList {
  list-style-type: decimal;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  row-gap: 6px;
}

.instructionsListItem {
  font-size: 1rem;
}

.instructionsList > li::marker {
  font-weight: bold;
  font-feature-settings: "lnum";
}

.instructionsCopy {
  line-height: 1.4;
  white-space: pre-line;
  padding-left: 10px;
}

.instructionsTitle {
  font-weight: bold;
}

.callout {
  background: lighten($red, 50);
  font-size: 0.75rem;
  position: relative;
}
