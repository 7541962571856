@import "assets/styles/variables.scss";

.hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.2));
  
  @include hero-background;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
