@import "assets/styles/variables.scss";

.callout {
  background: lighten($red, 50);
  font-size: 0.75rem;
  border-radius: 5px;
}

.calloutText {
  line-height: 14px;
}

.card {
  font-size: 14px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.section {
  padding: 15px;
}

.cardHeader {
  font-weight: 700;
  font-size: 1.125rem;
  margin-bottom: 10px;
}

.cardRow {
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  p {
    margin: 0;
    max-width: 50%;
  }

  .info {
    font-weight: 700;
    text-align: right;
  }

  &.activeReservation .info {
    font-weight: 300;
  }
}

.lowerContainer {
  background-color: #f5f5f5;

  .cardRow {
    margin-bottom: 5px;
  }
}

.rates {
  margin-bottom: 15px;
}

.ratesHeader {
  font-size: 12px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.button {
  font-size: 14px;
}
