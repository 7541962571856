.formWrapper {
  padding: 0 28px;
}

.loginLine {
  padding: 44px 0 39px;
}

.infoCard {
  background-color: #f3f3f3;
  padding: 20px 15px;
  border-radius: 4px;
}

.infoCard h3 {
  font-size: 1.125rem;
  margin-bottom: 0;
}

.infoText {
  font-size: 1rem;
}

.infoAnswer {
  line-height: 1.25rem;
}

.submitButton {
  font-size: 1.125rem;
}
