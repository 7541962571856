@import "assets/styles/variables.scss";
@import "src/variables.scss";

@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/proximanova-regular-webfont.woff');
}
@font-face {
  font-family: 'ProximaNova';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/proximanova-semibold-webfont.woff');
}

#root,
html,
body {
  height: 100%;
}

@import "bootstrap-icons/font/bootstrap-icons.css";
@import "~bootstrap/scss/bootstrap.scss";

html {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;

  @media screen and (min-width: $small-screen-min-breakpoint) {
    font-size: 18px;
  }
}

body {
  font-family: "ProximaNova", sans-serif !important;
}
