@import "assets/styles/variables.scss";

.body {
  flex: 1;
  height: 100%;
  max-width: 500px;

  @media screen and (width > 500px) {
    height: fit-content;
    padding: 0;
  }
}

.linkWrapper {
  padding-top: 10px;
  font-size: 0.875rem;
  text-align: center;
}

.cardsContainer {
  margin: 25px 0;

  @media screen and (width <= 500px) {
    flex: 1;
  }
}

.selectParkingLinkContainer {
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 20px 15px 30px;
  background-color: #fff;
  width: 100%;
  box-shadow: 0 -8px 8px 0 #00000015;
  display: flex;
  justify-content: center;
}

.selectParkingLink {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary;
  text-decoration: none;
  border: 1px solid $primary;
  border-radius: 4px;
  height: 45px;
  width: 100%;
  max-width: 500px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.emptyContainer {
  padding: 25px 20px;
  text-align: center;
  border: 1px solid #CECECE;
  border-radius: 5px;

  h5 {
    font-size: 18px;
    font-weight: 700;
  }

  p {
    margin-bottom: 0;
  }
}
