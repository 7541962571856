@import "assets/styles/variables";

.toastContainer {
  @media (min-width: 576px) {
    bottom: 85% !important;
  }
}

.toast {
  border-left: 3px solid $red;
  width: auto
}

.body {
  background-color: rgba(255, 255, 255, 0.75);
  border-bottom: none;
  color: black;
}
