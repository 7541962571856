@import "assets/styles/variables";
@import "~bootstrap/scss/functions.scss";

// styles for the availability section
.availability {
  padding: 20px 12px;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
}

.available,
.unavailable,
.soldOut,
.noReservation {
  display: flex;
  align-items: center;
  height: 24px;
  border-radius: 15px;
  padding: 4px 13px;
  font-size: 13px;
  color: $new-black;
  white-space: nowrap;
}

.available {
  background-color: #31C81933;
}

.unavailable {
  background: $grey-7;
}

.soldOut {
  background-color: #f7cdd4;
}

.noReservation {
  background: white;
  border: 1px solid $grey-1;
}
// styles for the availability section

@media screen and (width > 600px) {
  .box {
    margin: 0;
  }

  .changeDay {
    margin: 15px 0 0;
  }

  .availability {
    justify-content: center;
  }
}
