@import "assets/styles/variables";
@import "~bootstrap/scss/functions.scss";

.container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.navBarNew {
  background-color: $primary;
}

.title {
  font-size: 18px;
  font-weight: bold;
  padding: 35px 15px 25px;
  color: $new-black;
}

@media screen and (width > 600px) {
  .title {
    padding: 45px 0 25px;
  }
}

// styles for the date navigation section
.changeDay {
  margin: 0 12px 20px;
  display: flex;
  gap: 10px;

  @media screen and (width > 600px) {
    margin: 0 0 20px;
  }
}

.changeDay button {
  color: $medium-blue;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 20px;
  border: 1px solid $medium-blue;
  background-color: #fff;
  border-radius: 5px;
  flex: 1;
  text-align: center;
  cursor: pointer;
}

.changeDay button:disabled {
  background-color: $grey-8;
  border: none;
  color: $gray-600;
  cursor: not-allowed;
}

.promoCodeContainer {
  margin: 0 12px 20px;
  display: flex;
  align-items: center;

  @media screen and (width > 600px) {
    margin: 0 0 20px;
  }
}

.promoCodeWrapper {
  padding-left: 10px;
  color: #2b2b2b;
}

.header {
  font-size: 12px;
  text-transform: uppercase;
}

.redeemCode {
  font-size: 14px;
  font-weight: bold;
}

.redeemCodeWrapper {
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: $new-black;
  padding-top: 10px;
  text-align: center;
}

.redeemCodeLink {
  padding: 0 5px;
  text-decoration: none;
  color: $medium-blue;
  cursor: pointer;
}
