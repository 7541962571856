@import "assets/styles/variables.scss";

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.headline {
  text-align: center;
  margin-top: 56px;
  padding: 0 19px;
  white-space: nowrap;
  color: #2b2b2b;
}

.text {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  padding-top: 25px;
}

.cardsContainer {
  margin-top: 20px;
}

.cardWrapper {
  margin: 0 19px 20px;
}

.cardBody {
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 83px;
  padding: 16px 16px 16px 22px;
  color: $dark;

  &:hover {
    background-color: rgba(0, 113, 235, 0.1);

    .arrowIcon {
      filter: invert(34%) sepia(82%) saturate(5182%) hue-rotate(201deg)
        brightness(98%) contrast(101%);
    }
  }
}

.arrowIcon {
  filter: invert(15%) sepia(0%) saturate(0%) hue-rotate(174deg) brightness(95%)
    contrast(93%);
}

.cardHeader {
  display: flex;
}

.headerText {
  font-size: 16px;
  color: #2b2b2b;
  font-weight: 700;
  padding-left: 10px;
}
