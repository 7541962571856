// Variables for colors
$new-black: #2b2b2b; // This color is used in coreapp
$medium-blue: #0071eb;

// New gray colors (variables from HONK)
// TODO: sync with HONK
$grey-1: #595959;
$grey-2: #757575;
$grey-4: #b8b8b8;
$grey-7: #f0f0f0;
$grey-8: #f5f5f5;

// Breakpoints for responsive design across different devices
$mobile-max-breakpoint: 767px;
$tablet-min-breakpoint: 768px;
$tablet-max-breakpoint: 991px;
$small-screen-min-breakpoint: 992px;
$small-screen-max-breakpoint: 1200px;
