@import "assets/styles/variables.scss";

.item {
  padding: 18px;
  cursor: pointer;
}

.menuGroupLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  padding: 3rem 0;
}

.addMargin {
  margin: 15px 0px;
}

.menuLink {
  color: $black;
  &:hover {
    color: $black;
  }
}

.backgroundButton {
  background: none;
  border: none;
}

.emailAddress {
  font-size: 16px;
}
