@import "assets/styles/variables.scss";

.footer {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  gap: 5px;
  color: #9B9B9B;
  font-size: 16px;
  white-space: nowrap;
}

.link {
  color: #1972D2;
}
