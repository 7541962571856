@import "assets/styles/variables";
@import "~bootstrap/scss/functions.scss";

.infoCard {
  box-shadow: none;
  background: $grey-8;
  padding: 18px 14px;
  border-radius: 4px;
  margin: 20px 12px 10px;

  @media screen and (width > 600px) {
    margin: 20px 0 10px;
  }
}

.infoText {
  font-size: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.showMore {
  margin-top: 15px;
  border: solid 1px $medium-blue;
  border-radius: 20px;
  padding: 4px 13px;
  font-size: 0.875rem;
  color: $medium-blue;
  font-weight: bold;
  background-color: transparent;
}

.modalHeader {
  font-size: 1rem;
  margin-bottom: 0;
}

.modalInfoText {
  margin-bottom: 20px;
  font-size: 1rem;
}

.modalContent {
  max-height: 50vh;
  overflow-y: auto;
  border-bottom: 1px solid $gray-200;
}

.closeButton {
  width: 100%;
  font-size: 1.125rem;
  color: #fff;
  font-weight: bold;
  padding: 10px 17px;
  border: none;
  border-radius: 5px;
  background: $primary;
  margin-top: 20px;
}
