@import "assets/styles/variables";
@import "~bootstrap/scss/functions.scss";

.wrapper {
  padding: 20px 12px 0;

  @media screen and (width > 600px) {
    padding: 20px 0 0;
  }
}

.card {
  width: 100%;
  box-shadow: none;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: rgba(0, 113, 235, 0.1);
  color: $medium-blue;
  cursor: pointer;
}

.unavailableCard {
  @extend .card;
  background-color: $grey-7;
  color: $grey-2;
}

.ratePrice {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: bold;
  font-feature-settings: "lnum";
}

.rateCopy {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.soldout {
  font-size: 14px;
}

.emptyRatesWrapper {
  min-height: 92px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid $gray-400;
  background: white;
  border-radius: 5px;
  padding: 15px 20px;
}

.emptyRatesHeadline {
  font-weight: bold;
  font-size: 1.125rem;
  padding-bottom: 5px;
}

.priceArrowWrapper {
  display: flex;
}

.arrowIcon {
  padding-left: 10px;
  filter: invert(34%) sepia(82%) saturate(5182%) hue-rotate(201deg)
    brightness(98%) contrast(101%);
}
