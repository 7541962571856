@import "assets/styles/variables";

.mbsc-calendar-controls.mbsc-flex.mbsc-ios {
  justify-content: space-between;
  padding: 11px;
  position: relative;
}

.mbsc-calendar-week-days.mbsc-flex {
  background-color: #f5f5f5;
  padding: 5px 0;
}

.mbsc-ios.mbsc-calendar-week-day,
.mbsc-calendar-week-day {
  font-size: 18px;
  font-weight: bold;
  color: $new-black;
}

.mbsc-ios.mbsc-datepicker-inline {
  border-top: none;
}

.mbsc-datepicker .mbsc-calendar-slide {
  padding: 0;
}

.custom-nav {
  width: 100%;

  .mbsc-ios.mbsc-calendar-button.mbsc-button {
    color: $new-black;
    font-weight: bold;
    width: 100%;
  }
}

.mbsc-icon-button.mbsc-ios.mbsc-calendar-button.mbsc-button {
  color: $grey-4;
  position: absolute;
  width: 90px;
  z-index: 2;

  &.custom-prev {
    left: 0;
    justify-content: flex-start;
  }

  &.custom-next {
    right: 0;
    justify-content: flex-end;
  }
}

.mbsc-ios.mbsc-calendar-today {
  color: $new-black;
}

.mbsc-ios.mbsc-calendar-day-text {
  font-size: 18px;
  color: $new-black;
  padding: 5px;
  margin: 0;
  height: 1.444445em;
  line-height: 1.444445em;
  width: 1.444445em;
}

.mbsc-calendar-cell-inner.mbsc-calendar-day-inner.mbsc-ios {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mbsc-ios.mbsc-datepicker .mbsc-hover .mbsc-calendar-day-text,
.mbsc-ios.mbsc-datepicker .mbsc-highlighted .mbsc-calendar-day-text {
  font-size: 18px;
  padding: 5px;
  margin: 0;
}

@media screen and (width > 600px) {
  .mbsc-calendar-controls.mbsc-flex.mbsc-ios {
    padding: 11px 0;
  }

  .mbsc-datepicker .mbsc-calendar-slide-active {
    padding-right: 10px;
  }

  .mbsc-datepicker .mbsc-calendar-slide-active + .mbsc-calendar-slide-active {
    padding-right: 0;
    padding-left: 10px;
  }

  .custom-nav .mbsc-ios.mbsc-calendar-button.mbsc-button {
    padding-right: 10px;
    width: 50%;
  }

  .custom-nav
    .mbsc-ios.mbsc-calendar-button.mbsc-button
    + .mbsc-ios.mbsc-calendar-button.mbsc-button {
    padding-left: 10px;
    padding-right: 8px;
  }
}
